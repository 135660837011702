var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: { headers: _vm.headers, items: _vm.items },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("ref-subtitle", [
                                  _vm._v("Manage Organizations")
                                ]),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      color: "primary lighten-1",
                                      dark: "",
                                      outlined: ""
                                    },
                                    on: { click: _vm.showOrganizationForm }
                                  },
                                  [_vm._v("New Organization")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item.active",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.active
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "activate",
                                                  attrs: {
                                                    small: "",
                                                    color: "green"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.activate(
                                                        item.id,
                                                        false
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-circle")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Disable Organization")])]
                            )
                          : _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "activate",
                                                  attrs: {
                                                    small: "",
                                                    color: "red"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.activate(
                                                        item.id,
                                                        true
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-circle")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Enable Organization")])]
                            )
                      ]
                    }
                  },
                  {
                    key: "item.domainName",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(item.domainName) +
                            " (" +
                            _vm._s(item.domainId) +
                            ") "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.directories",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.directories && item.directories.length > 0
                          ? _c(
                              "div",
                              _vm._l(item.directories, function(d) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: d.id,
                                    staticClass: "mr-2 my-2 ",
                                    attrs: { small: "" }
                                  },
                                  [_vm._v(_vm._s(d.name))]
                                )
                              }),
                              1
                            )
                          : _c("div")
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-4",
                            attrs: { title: "Delete Organization", small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deleteOrganization(item.id)
                              }
                            }
                          },
                          [_vm._v("mdi-delete-circle")]
                        ),
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-4",
                            attrs: { title: "Edit Organization", small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.editItem(item.id)
                              }
                            }
                          },
                          [_vm._v("mdi-pencil")]
                        ),
                        !item.apiKey
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  title: "Add API Credentials",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.showApiCredentialsForm(item.id)
                                  }
                                }
                              },
                              [_vm._v("mdi-lock")]
                            )
                          : _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                attrs: { title: "Disable API", small: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.disableApi(item.id)
                                  }
                                }
                              },
                              [_vm._v("mdi-lock-remove")]
                            ),
                        !item.wsKey
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  title: "Add Websocket Key",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.showWsCredentialsForm(item.id)
                                  }
                                }
                              },
                              [_vm._v("mdi-chat")]
                            )
                          : _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  title: "Disable Websocket Key",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.disableWs(item.id)
                                  }
                                }
                              },
                              [_vm._v("mdi-chat-remove")]
                            ),
                        !item.connKey
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  title: "Add Connector Key",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.showConnCredentialsForm(item.id)
                                  }
                                }
                              },
                              [_vm._v("mdi-phone-classic")]
                            )
                          : _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  title: "Disable Connector Key",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.disableConn(item.id)
                                  }
                                }
                              },
                              [_vm._v("mdi-phone-classic-off")]
                            )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("organization-form"),
      _c("api-credentials-form"),
      _c("ws-credentials-form"),
      _c("conn-credentials-form"),
      _c("confirm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
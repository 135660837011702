var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px" },
      model: {
        value: _vm.connKeyDialog,
        callback: function($$v) {
          _vm.connKeyDialog = $$v
        },
        expression: "connKeyDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v("Create Connector Key")
            ])
          ]),
          _c(
            "v-card-text",
            [
              !_vm.generated
                ? _c("h3", { staticClass: "mb-2" }, [
                    _c("span", [_vm._v("Click to generate a ")]),
                    _vm._v("Connector Key for: "),
                    _c("code", [_vm._v(_vm._s(_vm.organization.name))])
                  ])
                : _vm._e(),
              _vm.generated
                ? _c("h3", { staticClass: "mb-2" }, [
                    _vm._v(
                      " You can only view this key once and is not recoverable. Treat it like a password and do not share it. If you lose it, you'll need to delete it and create a new one. "
                    )
                  ])
                : _vm._e(),
              _vm.generated
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("v-textarea", {
                            staticClass: "api-key",
                            attrs: {
                              rows: "7",
                              readonly: "",
                              label: "Connector Token:"
                            },
                            model: {
                              value: _vm.connKey,
                              callback: function($$v) {
                                _vm.connKey = $$v
                              },
                              expression: "connKey"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.generated
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            staticClass: "api-expires",
                            attrs: { label: "Days Until Expired" },
                            model: {
                              value: _vm.expiresInDays,
                              callback: function($$v) {
                                _vm.expiresInDays = $$v
                              },
                              expression: "expiresInDays"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              !_vm.connKey
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mb-4",
                      attrs: { color: "red darken-1", outlined: "", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                : _vm._e(),
              _c("v-spacer"),
              !_vm.connKey
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        color: "green darken-1",
                        outlined: "",
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.generateKey(_vm.organization.id)
                        }
                      }
                    },
                    [_vm._v("Generate Connector Key")]
                  )
                : _vm._e(),
              _vm.connKey
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        color: "green darken-1",
                        outlined: "",
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                : _vm._e(),
              _vm.connKey ? _c("v-spacer") : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ref-subtitle", [_vm._v("Manage Docs")]),
      _c("ref-body-text", { staticClass: "mb-4" }, [
        _vm._v(
          "The management interface is only available to admin users. It uses a data table, a composable for the data, and a composable for the confirmation. Dig into the source code to see the pattern and inline-comments."
        )
      ]),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: { headers: _vm.headers, items: _vm.items },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { "max-width": "900px" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      color:
                                                        "primary lighten-1",
                                                      dark: "",
                                                      outlined: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("New Document")]
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.dialogNewEditItem,
                                      callback: function($$v) {
                                        _vm.dialogNewEditItem = $$v
                                      },
                                      expression: "dialogNewEditItem"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c("v-card-title", [
                                          _c(
                                            "span",
                                            { staticClass: "headline" },
                                            [_vm._v(_vm._s(_vm.formTitle))]
                                          )
                                        ]),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-container",
                                              [
                                                _c(
                                                  "v-form",
                                                  {
                                                    model: {
                                                      value: _vm.validForm,
                                                      callback: function($$v) {
                                                        _vm.validForm = $$v
                                                      },
                                                      expression: "validForm"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "6",
                                                              sm: "6"
                                                            }
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              attrs: {
                                                                dense: "",
                                                                items: _vm.cats,
                                                                label:
                                                                  "Category",
                                                                outlined: "",
                                                                rules:
                                                                  _vm.rules
                                                                    .required
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemCat,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.itemCat = $$v
                                                                },
                                                                expression:
                                                                  "itemCat"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "6",
                                                              sm: "6"
                                                            }
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              attrs: {
                                                                dense: "",
                                                                items:
                                                                  _vm.topics,
                                                                label: "Topics",
                                                                outlined: "",
                                                                rules:
                                                                  _vm.rules
                                                                    .required
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemTopic,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.itemTopic = $$v
                                                                },
                                                                expression:
                                                                  "itemTopic"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label:
                                                                  "Document Title/FAQ Question",
                                                                placeholder: "",
                                                                dense: "",
                                                                outlined: "",
                                                                rules:
                                                                  _vm.rules
                                                                    .required
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemTitle,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.itemTitle = $$v
                                                                },
                                                                expression:
                                                                  "itemTitle"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c("v-textarea", {
                                                              attrs: {
                                                                outlined: "",
                                                                rules:
                                                                  _vm.rules
                                                                    .required,
                                                                label:
                                                                  "Document Content/FAQ Answer",
                                                                placeholder:
                                                                  "The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemContent,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.itemContent = $$v
                                                                },
                                                                expression:
                                                                  "itemContent"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "error",
                                                  outlined: "",
                                                  text: ""
                                                },
                                                on: { click: _vm.cancelNewItem }
                                              },
                                              [_vm._v("Cancel")]
                                            ),
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  disabled: !_vm.validForm,
                                                  color: "green darken-1",
                                                  outlined: "",
                                                  text: ""
                                                },
                                                on: { click: _vm.saveNewItem }
                                              },
                                              [_vm._v("Save")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-4",
                            attrs: { title: "Delete", small: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteDoc(item.id)
                              }
                            }
                          },
                          [_vm._v("mdi-delete-circle")]
                        ),
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-4",
                            attrs: { title: "Delete", small: "" },
                            on: {
                              click: function($event) {
                                return _vm.editItem(item.id)
                              }
                            }
                          },
                          [_vm._v("mdi-pencil")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("confirm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
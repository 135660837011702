

























































































































































import Vue from 'vue';
import '@/lib/log';

import { defineComponent } from '@vue/composition-api';
import { useOrganization } from '@/module/organization';
import { useConfirmation } from '@/components/confirmation/Confirmation.vue';
import Confirm from '@/components/confirmation/Confirmation.vue';
import OrganizationForm, { useOrganizationForm } from '@/module/organization/OrganizationForm.vue';
import ApiCredentialsForm, {
  useApiCredentialsForm
} from '@/module/organization/ApiCredentialsForm.vue';
import WsCredentialsForm, {
  useWsCredentialsForm
} from '@/module/organization/WebsocketCredentialsForm.vue';

import ConnCredentialsForm, {
  useConnCredentialsForm
} from '@/module/organization/ConnectorCredentialsForm.vue';

// import OrganizationForm, { useOrganizationForm } from '@/components/forms/OrganizationForm';

export default defineComponent({
  name: 'OrganizationsList',
  components: {
    Confirm,
    OrganizationForm,
    ApiCredentialsForm,
    WsCredentialsForm,
    ConnCredentialsForm
  },
  setup() {
    Vue.$log.debug('Loaded OrganizationsList');
    // Alias open to confirm so it's a bit more semantic
    const { open: confirm } = useConfirmation();

    // Get items for the list of orgs
    const {
      items,
      deleteItem,
      updateItem,
      deleteApiKey,
      deleteWsKey,
      deleteConnKey
    } = useOrganization();
    // Pull in items to show org form for new/edit
    const { showOrganizationForm, editItem } = useOrganizationForm();

    // API Credentials form
    const { showApiCredentialsForm } = useApiCredentialsForm();

    // WS Connection Form
    const { showWsCredentialsForm } = useWsCredentialsForm();

    const { showConnCredentialsForm } = useConnCredentialsForm();

    const headers = [
      { value: 'active', align: 'center', width: '50px' },
      {
        text: 'Name',
        align: 'start',
        value: 'name'
      },
      { text: 'Domain', value: 'domainName' },
      { text: 'Domain ID', value: 'domainId', align: 'start' },
      { text: 'Namespace', value: 'path' },
      { text: 'Actions', value: 'actions', sortable: false, width: '200px' }
    ];

    const deleteOrganization = (id: string) => {
      confirm({
        message: 'Are you sure you want to delete this organization?'
      }).then((confirmed: boolean) => {
        if (confirmed) {
          deleteItem(id);
        }
      });
    };

    const activate = (id: string, active: boolean) => {
      updateItem({ id, active });
    };

    const disableApi = (id: string) => {
      confirm({
        message:
          'Are you sure you want to remove the API key? Organization will no longer have API access to this app.'
      }).then((confirmed: boolean) => {
        if (confirmed) {
          deleteApiKey(id);
        }
      });
    };

    const disableWs = (id: string) => {
      confirm({
        message:
          'Are you sure you want to remove the Websocket Connection Key? Organization will no longer have websocket access to this app.'
      }).then((confirmed: boolean) => {
        if (confirmed) {
          deleteWsKey(id);
        }
      });
    };

    const disableConn = (id: string) => {
      confirm({
        message:
          'Are you sure you want to remove the Connector Key? Organization will no longer have connector access to this app.'
      }).then((confirmed: boolean) => {
        if (confirmed) {
          deleteConnKey(id);
        }
      });
    };

    return {
      items,
      headers,
      deleteItem,
      deleteOrganization,
      activate,
      showOrganizationForm,
      showApiCredentialsForm,
      showWsCredentialsForm,
      showConnCredentialsForm,
      disableApi,
      disableWs,
      disableConn,
      editItem
    };
  }
});

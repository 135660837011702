import axios from 'axios';
import Vue from 'vue';
import '@/lib/log';

import { computed, reactive, ref } from '@vue/composition-api';
import { OrganizationModel } from './organization.interface';
import { BaseItemState, BaseUseItemModel } from '../shared/base.use-crud.interface';
import { useUi } from '@/module/ui';

const apiPath = '/organization';

const emptyItem: OrganizationModel = reactive({
  id: '',
  name: '',
  label: '',
  email: '',
  apiKey: '',
  wsKey: '',
  domainId: '',
  domainName: ''
});

const state: BaseItemState<OrganizationModel> = {
  items: ref([]),
  selectedItemId: ref(''),
  selectedItem: ref({
    ...emptyItem
  }),
  createdItemId: ref(''),
  createdItem: ref({
    ...emptyItem
  })
};

// Sometimes we just need to use any so our brains don't explode
// eslint-disable-next-line
const items: any = ref([]);
// eslint-disable-next-line
const selectedItem: any = reactive({});
// eslint-disable-next-line
// const selectedItemId: any = ref('');

// eslint-disable-next-line
export function useOrganization(query = ''): BaseUseItemModel<OrganizationModel> & any {
  Vue.$log.debug('Loaded for query', query);
  const { waitOff, waitOn } = useUi();

  const selectItem = async (id: string): Promise<OrganizationModel | undefined> => {
    if (id) {
      Vue.$log.debug(`Selecting item ${id} from items: `, items.value);
      const item = items.value.find((i: OrganizationModel) => i.id === id || i.uuid === id);
      if (item) {
        state.selectedItemId.value = item?.id ? item.id : '';
        state.selectedItem.value = item;
        selectedItem.value = item;
        return selectedItem.value as Promise<OrganizationModel>;
      }
    }
  };

  const validItem = async (item: OrganizationModel) => {
    if (!item.name || !item.domainName || !item.domainId) {
      const errMessage = 'Item not valid. You need a name, domainName and domainId.';
      Vue.$log.error(errMessage, item);
      throw new Error(errMessage);
    }
    return true;
  };

  const validUpdateItem = async (item: Partial<OrganizationModel>) => {
    if (!item.id && Object.keys(item).length < 2) {
      const errMessage = 'Item not valid. Need an id to update and at least one field.';
      Vue.$log.error(errMessage, item);
      throw new Error(errMessage);
    }
    return true;
  };

  const getItems = async (query = '') => {
    try {
      waitOn('Getting Orgs');
      const url = `${apiPath}${query ? '/query/?' + query : query}`;
      const res = await axios.get<OrganizationModel[]>(url);
      Vue.$log.debug('Returned from API: ', res.data);
      items.value = res.data;
      state.items.value = res.data;
    } catch (err) {
      const errMessage = 'Error loading.';
      Vue.$log.error(errMessage, err);
      throw new Error(errMessage);
    } finally {
      waitOff();
    }
  };

  const getItem = async (id: string) => {
    if (!id) {
      throw new Error('Can not get item without ID');
    }
    try {
      waitOn('Getting Org');
      const res = await axios.get<OrganizationModel[]>(`${apiPath}/${id}`);
      Vue.$log.debug('Returned from API: ', res.data);
      items.value = res.data;
    } catch (err) {
      const errMessage = 'Error loading.';
      Vue.$log.error(errMessage, err);
      throw new Error(errMessage);
    } finally {
      waitOff();
    }
  };

  const createItem = async (item: OrganizationModel) => {
    if (validItem(item)) {
      Vue.$log.debug('Creating new item: ', item);
      try {
        waitOn('Creating Org');
        const res = await axios.post<OrganizationModel>(`${apiPath}`, item);
        if (res.data) {
          Vue.$log.debug('Successfully created item. Refreshing items.');
          state.createdItem.value = res.data;
          state.createdItemId.value = res.data.id!; // eslint-disable-line
          await getItems();
          return res.data;
        } else {
          const errMessage = 'Invalid response creating item';
          Vue.$log.error(errMessage, res);
        }
      } catch (err) {
        const errMessage = 'Error caught creating item.';
        Vue.$log.error(errMessage, err);
        throw new Error(errMessage);
      } finally {
        waitOff();
      }
    }
  };

  const updateItem = async (item: Partial<OrganizationModel>) => {
    if (validUpdateItem(item)) {
      Vue.$log.debug('Updating item: ', item);
      try {
        waitOn('Updating Org');
        const res = await axios.put<Partial<OrganizationModel>>(`${apiPath}`, item);
        if (res.data) {
          Vue.$log.debug('Successfully updated item. Refreshing items.');
          return res.data;
        } else {
          const errMessage = 'Invalid response updating item';
          Vue.$log.error(errMessage, res);
          throw new Error(errMessage);
        }
      } catch (err) {
        const errMessage = 'Error updating item';
        Vue.$log.error(errMessage, err);
        throw new Error(errMessage);
      } finally {
        await getItems();

        waitOff();
      }
    }
  };

  const deleteItem = async (id: string) => {
    if (!id) {
      Vue.$log.error('Can not delete item without ID');
      return;
    }
    Vue.$log.debug('Deleting item: ', id);
    try {
      waitOn('Deleting Org');
      const res = await axios.delete<OrganizationModel>(`${apiPath}/${id}`);
      if (res.data) {
        Vue.$log.debug('Successfully deleted item. Refreshing items.');
        await getItems();
      } else {
        Vue.$log.error('Invalid response deleting item: ', res);
      }
    } catch (err) {
      Vue.$log.error('Error caught deleting item.', id, err);
    } finally {
      waitOff();
    }
  };

  const createApiKey = async (id: string, expiresIn: number): Promise<string | undefined> => {
    if (!id) {
      Vue.$log.error('Can not create token without organization ID');
      return;
    }
    Vue.$log.debug('Adding API key: ', id);
    try {
      waitOn('Enabling API');
      const res = await axios.post(`${apiPath}/token`, { id, expiresIn });
      if (res.data) {
        return res.data['token'];
      } else {
        Vue.$log.error('Invalid response trying to create token: ', res);
      }
    } catch (err) {
      Vue.$log.error('Error caught adding token', id, err);
    } finally {
      getItems();
      waitOff();
    }
  };

  const deleteApiKey = async (id: string): Promise<OrganizationModel | undefined> => {
    if (!id) {
      Vue.$log.error('Can not delete item without ID');
      return;
    }
    Vue.$log.debug('Deleting API Key: ', id);
    try {
      waitOn('Disabling API');
      const res = await axios.delete(`${apiPath}/token/${id}`);
      if (res.data) {
        Vue.$log.debug('Deleted API Key: ', res.data);
        return res.data;
      } else {
        Vue.$log.error('Invalid response trying to delete token: ', res);
      }
    } catch (err) {
      Vue.$log.error('Error caught deleting token', id, err);
    } finally {
      await getItems();
      waitOff();
    }
  };

  const createWsKey = async (id: string, expiresIn: number): Promise<string | undefined> => {
    if (!id) {
      Vue.$log.error('Can not create websocket connection token without organization ID');
      return;
    }
    Vue.$log.debug('Adding WS connection token: ', id);
    try {
      waitOn('Enabling WS');
      const res = await axios.post(`${apiPath}/ws-token/`, { id, expiresIn });
      if (res.data) {
        return res.data['token'];
      } else {
        Vue.$log.error('Invalid response trying to create websocket connection token: ', res);
      }
    } catch (err) {
      Vue.$log.error('Error caught adding token', id, err);
    } finally {
      getItems();
      waitOff();
    }
  };

  const deleteWsKey = async (id: string): Promise<OrganizationModel | undefined> => {
    if (!id) {
      Vue.$log.error('Can not delete item without ID');
      return;
    }
    Vue.$log.debug('Deleting WS connection token: ', id);
    try {
      waitOn('Disabling API');
      const res = await axios.delete(`${apiPath}/ws-token/${id}`);
      if (res.data) {
        Vue.$log.debug('Deleted API Key: ', res.data);
        return res.data;
      } else {
        Vue.$log.error('Invalid response trying to delete websocket connection token: ', res);
      }
    } catch (err) {
      Vue.$log.error('Error caught deleting token', id, err);
    } finally {
      await getItems();
      waitOff();
    }
  };

  const createConnKey = async (id: string, expiresIn: number): Promise<string | undefined> => {
    if (!id) {
      Vue.$log.error('Can not create connector connection token without organization ID');
      return;
    }
    Vue.$log.debug('Adding WS connection token: ', id);
    try {
      waitOn('Get Conn Key');
      const res = await axios.post(`${apiPath}/connector-token/`, { id, expiresIn });
      if (res.data) {
        return res.data['token'];
      } else {
        Vue.$log.error('Invalid response trying to create connector connection token: ', res);
      }
    } catch (err) {
      Vue.$log.error('Error caught adding token', id, err);
    } finally {
      getItems();
      waitOff();
    }
  };

  const deleteConnKey = async (id: string): Promise<OrganizationModel | undefined> => {
    if (!id) {
      Vue.$log.error('Can not delete item without ID');
      return;
    }
    Vue.$log.debug('Deleting Connector connection token: ', id);
    try {
      waitOn('Delete Conn Key');
      const res = await axios.delete(`${apiPath}/connector-token/${id}`);
      if (res.data) {
        Vue.$log.debug('Deleted Connector Key: ', res.data);
        return res.data;
      } else {
        Vue.$log.error('Invalid response trying to delete connector connection token: ', res);
      }
    } catch (err) {
      Vue.$log.error('Error caught deleting token', id, err);
    } finally {
      await getItems();
      waitOff();
    }
  };

  // Get items on load
  getItems(query);

  return {
    items: computed(() => items.value),
    emptyItem: computed(() => emptyItem),
    selectedItem: computed(() => selectedItem),
    // items: computed(() => state.items),
    selectedItemId: computed(() => state.selectedItemId),
    // selectedItem: computed(() => state.selectedItem),
    createdItemId: computed(() => state.createdItemId),
    createdItem: computed(() => state.createdItem),
    getItem,
    getItems,
    createItem,
    updateItem,
    selectItem,
    deleteItem,
    createApiKey,
    deleteApiKey,
    createWsKey,
    deleteWsKey,
    createConnKey,
    deleteConnKey
  };
}

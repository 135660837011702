




















































































































import Vue from 'vue';
import '@/lib/log';

import { defineComponent, ref } from '@vue/composition-api';
import { useDoc } from '@/module/doc';
import { useConfirmation } from '@/components/confirmation/Confirmation.vue';
import Confirm from '@/components/confirmation/Confirmation.vue';

export default defineComponent({
  name: 'DocsList',
  components: { Confirm },
  setup() {
    const { items, deleteItem, createItem } = useDoc();
    const { open, dialog } = useConfirmation();

    const dialogNewEditItem = ref(false);
    const formTitle = ref('New Document');
    const validForm = ref(false);

    const headers = [
      {
        text: 'Cat',
        align: 'start',
        value: 'category',
        width: '100px'
      },
      { text: 'Topic', value: 'topic' },
      { text: 'Title', value: 'title' },
      { text: 'Content', value: 'content', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
    ];

    const cats = ['faq', 'docs'];
    const topics = ['General Questions'];

    // TODO: Expand on TS base classes so we can use models here
    const itemCat = ref('');
    const itemTopic = ref('');
    const itemTitle = ref('');
    const itemContent = ref('');
    //

    const rules = {
      required: [(v: string) => !!v || 'Required.']
    };

    const resetItem = () => {
      itemCat.value = '';
      itemTitle.value = '';
      itemTopic.value = '';
      itemContent.value = '';
      validForm.value = false;
    };

    const cancelNewItem = () => {
      resetItem();
      dialogNewEditItem.value = false;
    };

    const deleteDoc = (id: string) => {
      open().then((confirmed: boolean) => {
        if (confirmed) {
          deleteItem(id);
        }
      });
    };

    const saveNewItem = () => {
      Vue.$log.debug('Saving item:');
      createItem({
        category: itemCat.value,
        topic: itemTopic.value,
        title: itemTitle.value,
        content: itemContent.value
      }).then(() => {
        resetItem();
        dialogNewEditItem.value = false;
        Vue.$log.debug('Saved new item.');
      });
    };

    const editItem = () => {
      open({ title: 'TODO', message: 'You up for the task?' });
    };

    return {
      dialog,
      items,
      cats,
      topics,
      headers,
      deleteItem,
      editItem,
      deleteDoc,
      dialogNewEditItem,
      formTitle,
      saveNewItem,
      cancelNewItem,
      validForm,
      rules,
      itemCat,
      itemTopic,
      itemTitle,
      itemContent
    };
  }
});

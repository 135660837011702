









































































import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import Vue from 'vue';
import '@/lib/log';

import { useOrganization, OrganizationModel } from '@/module/organization';
import { usePortal, PortalOrganization } from '@/module/portal';

const { emptyItem, createItem, updateItem, selectItem } = useOrganization();
const { portalOrgs } = usePortal();

const state = reactive({
  organizationDialog: false,
  validForm: false,
  errorMessage: '',
  formTitle: 'New Organization',
  editing: false
});

const organization: OrganizationModel = reactive({ ...emptyItem.value });

const showOrganizationForm = () => {
  Vue.$log.debug('Showing organization form. Current state: ', state);
  state.organizationDialog = true;
};

const reset = () => {
  Object.assign(organization, {
    ...emptyItem.value,
    _id: undefined,
    __v: undefined,
    uuid: undefined,
    createdAt: undefined,
    updatedAt: undefined
  });
  state.editing = false;
  state.errorMessage = '';
  state.formTitle = 'New Organization';
  state.validForm = false;
  selectItem().then(() => {
    Vue.$log.debug('Unselected item.');
  });
};

const cancel = () => {
  reset();
  state.organizationDialog = false;
};

const rules = {
  required: [(v: string) => !!v || 'Required.'],
  email: [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ]
};

const editItem = (id: string) => {
  state.editing = true;
  selectItem(id).then((o: OrganizationModel) => {
    Object.assign(organization, o);
    state.organizationDialog = true;
  });
  state.formTitle = 'Edit Organization - ';
};

const createOrUpdate = (item: OrganizationModel) => {
  if (state.editing) {
    updateItem({
      ...item
    }).then((updated: OrganizationModel) => {
      reset();
      state.organizationDialog = false;
      Vue.$log.debug('Updated item.', updated);
    });
  } else {
    createItem({
      ...item,
      id: undefined
    }).then((created: OrganizationModel) => {
      reset();
      state.organizationDialog = false;
      Vue.$log.debug('Saved new item.', created);
    });
  }
  //
};

const orgSelected = (id: string) => {
  const selected: PortalOrganization = portalOrgs.value.find(
    (o: PortalOrganization) => o.orgId === id
  );
  organization.domainName = selected.domain;
  organization.domainId = selected.orgId;
  organization.name = selected.domain;
};

export function useOrganizationForm() {
  return { showOrganizationForm, editItem, organization };
}

export default defineComponent({
  name: 'OrganizationForm',

  setup() {
    return {
      ...toRefs(state),
      createOrUpdate,
      rules,
      organization,
      cancel,
      portalOrgs,
      orgSelected
    };
  }
  //
});

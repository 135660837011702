var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "900px" },
          model: {
            value: _vm.organizationDialog,
            callback: function($$v) {
              _vm.organizationDialog = $$v
            },
            expression: "organizationDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v(_vm._s(_vm.formTitle))
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.validForm,
                        callback: function($$v) {
                          _vm.validForm = $$v
                        },
                        expression: "validForm"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  "This service has an enforced reference back to Five9 domains and app-provisioned users in the PS Portal."
                                )
                              ])
                            ]),
                            _c("p", [
                              _vm._v(
                                " If you do not see a reference to a Five9 domain in the select list, you'll need to ensure that a Portal user belonging to that particular domain has been setup, has been assigned this application with an app config "
                              ),
                              _c("strong", [_vm._v("and")]),
                              _vm._v(
                                " that the user has logged into the Portal at least twice. This will ensure that their domain details have propagated to the reference-based apps. "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  disabled: _vm.editing,
                                  items: _vm.portalOrgs,
                                  label: "Five9 Domains from PS Portal",
                                  rules: _vm.rules.required,
                                  dense: "",
                                  outlined: "",
                                  "item-text": "domain",
                                  "item-value": "orgId",
                                  autocomplete: "new"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.orgSelected($event)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Service Organization Name",
                                  placeholder: "Some Company Name",
                                  dense: "",
                                  outlined: "",
                                  rules: _vm.rules.required
                                },
                                model: {
                                  value: _vm.organization.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.organization, "name", $$v)
                                  },
                                  expression: "organization.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", outlined: "", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.validForm,
                        color: "green darken-1",
                        outlined: "",
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.createOrUpdate(_vm.organization)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.editing ? "Update" : "Create") + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

































































import Vue from 'vue';
import '@/lib/log';

import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import { useOrganization, OrganizationModel } from '@/module/organization';

const { selectItem: selectOrganization, createWsKey } = useOrganization();

const state = reactive({
  wsKeyDialog: false,
  wsKey: '',
  expiresInDays: 365,
  generated: false,
  organization: {}
});

const reset = () => {
  state.wsKey = '';
  state.organization = {};
  state.generated = false;
};

const close = () => {
  state.wsKeyDialog = false;
  reset();
};

const showWsCredentialsForm = (id: string) => {
  selectOrganization(id).then((o: OrganizationModel) => {
    state.organization = { ...o };
    state.wsKeyDialog = true;
    Vue.$log.debug('Selected org: ', state.organization);
  });
  Vue.$log.debug('Showing WS connection credentials form. Current state: ', state);
};

export function useWsCredentialsForm() {
  return { showWsCredentialsForm };
}

export default defineComponent({
  name: 'WsCredentialsForm',

  setup() {
    const generateKey = (id: string) => {
      createWsKey(id, state.expiresInDays).then((key: string) => {
        state.wsKey = key;
        state.generated = true;
      });
    };

    return { ...toRefs(state), close, generateKey };
  }
});

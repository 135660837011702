import axios from 'axios';
import Vue from 'vue';
import '@/lib/log';

import { computed, ref } from '@vue/composition-api';
import { PortalAppUser, PortalOrganization } from './portal.interface';
import { useUi } from '@/module/ui';
const apiPath = '/portal';

// eslint-disable-next-line
const users: any = ref([]);
// eslint-disable-next-line
const portalOrgs: any = ref([]);

export function usePortal() {
  Vue.$log.debug('Loaded usePortal()');
  const { waitOff, waitOn } = useUi();

  const getUsers = async () => {
    try {
      waitOn('Portal Users');
      const url = `${apiPath}/user`;
      const res = await axios.get<PortalAppUser[]>(url);
      Vue.$log.debug('Returned from API: ', res.data);
      // No need to let the user see their app config in the browser
      users.value = res.data;
      portalOrgs.value = [
        ...new Set(
          res.data.map((u: PortalAppUser) => {
            return {
              orgId: u.five9.orgId,
              domain: u.five9.domain
            } as PortalOrganization;
          })
        )
      ];
    } catch (err) {
      const errMessage = 'Error Loading Portal Users';
      Vue.$log.error(errMessage, err);
      throw new Error(errMessage);
      //
    } finally {
      waitOff();
    }
  };

  getUsers();

  return {
    users: computed(() => users.value),
    portalOrgs: computed(() => portalOrgs.value)
  };
}

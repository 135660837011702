
































































import Vue from 'vue';
import '@/lib/log';

import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import { useOrganization, OrganizationModel } from '@/module/organization';

const { selectItem: selectOrganization, createApiKey } = useOrganization();

const state = reactive({
  apiKeyDialog: false,
  apiKey: '',
  expiresInDays: 365,
  generated: false,
  organization: {}
});

const reset = () => {
  state.apiKey = '';
  state.organization = {};
  state.generated = false;
};

const close = () => {
  state.apiKeyDialog = false;
  reset();
};

const showApiCredentialsForm = (id: string) => {
  selectOrganization(id).then((o: OrganizationModel) => {
    state.organization = { ...o };
    state.apiKeyDialog = true;
    Vue.$log.debug('Selected org: ', state.organization);
  });
  Vue.$log.debug('Showing API credentials form. Current state: ', state);
};

export function useApiCredentialsForm() {
  return { showApiCredentialsForm };
}

export default defineComponent({
  name: 'ApiCredentialsForm',

  setup() {
    const generateKey = (id: string) => {
      createApiKey(id, state.expiresInDays).then((key: string) => {
        state.apiKey = key;
        state.generated = true;
      });
    };

    return { ...toRefs(state), close, generateKey };
  }
});

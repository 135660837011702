export class PortalUserFive9 {
  userId?: string;
  orgId?: string;
  domain?: string;
}

export interface PortalAppUser {
  id: string;
  appOrganizationId: string;
  username: string;
  first?: string;
  last?: string;
  company?: string;
  five9: PortalUserFive9;
  // eslint-disable-next-line
  appConfig?: any;
}

export interface PortalOrganization {
  orgId: string;
  domain: string;
}
